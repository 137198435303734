import { render, staticRenderFns } from "./DevicePoolsView.vue?vue&type=template&id=ffef59e4&scoped=true"
import script from "./DevicePoolsView.vue?vue&type=script&setup=true&lang=js"
export * from "./DevicePoolsView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffef59e4",
  null
  
)

export default component.exports